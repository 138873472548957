<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="d-flex justify-content-end">
          <feather-icon
            v-if="organizationRequiresEditConfirmation"
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Edit')"
            :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
            size="36"
            class="ml-1 cursor-pointer"
            @click="isEditAble()"
          />
        </div>
      </b-col>
    </b-row>
    <warehouse-form
      v-if="warehouse.id"
      ref="warehouse-form"
      :is-edit="isEdit"
    />
    <div v-else>
      <div v-if="!error">
        <b-row>
          <b-col md="6">
            <b-skeleton type="input" />
          </b-col>
          <b-col md="6">
            <b-skeleton type="input" />
          </b-col>
        </b-row>
        <b-row class="pt-2">
          <b-col md="6">
            <b-skeleton type="input" />
          </b-col>
          <b-col md="6">
            <b-skeleton type="input" />
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <component-not-found
          title="Warehouse List"
          :button-link="{ name: 'settings-fulfillment-warehouses'}"
        />
      </div>
    </div>
  </div>

</template>

<script>

import {
  BRow, BCol, BSkeleton, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import WarehouseForm from '../components/WarehouseForm.vue'
import config from '../warehouseConfig'

export default {
  name: 'WarehouseUpdate',
  components: {
    WarehouseForm,
    BRow,
    BCol,
    BSkeleton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      error: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['warehouse-form']) this.$refs['warehouse-form'].showConfirmationModal(next)
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    warehouse() {
      return this.$store.state[this.MODULE_NAME].warehouseForm
    },
  },
  mounted() {
    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(res => {
      const { data } = res.data
      const warehouse = this.mappingFields([...Object.keys(this.fields), 'locations'], data, {
        country_id: data.country,
        state_id: data.state,
        id: data.id,
      })
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, warehouse)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_WAREHOUSE_FORM_CLONE`, warehouse)
    }).catch(err => {
      this.error = true
      this.errorNotification(this, err)
    })
  },
  created() {
    this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
  },
  methods: {
    isEditAble() {
      return this.isEdit = !this.isEdit
    },
  },
  setup() {
    const MODULE_NAME = 'warehouse'
    const MODULE_NAME_CLONE = 'cloneData'
    const { fields } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      fields,
    }
  },
}
</script>

<style>

</style>
